<!-- eslint-disable no-tabs -->

<template>
  <div class="pack-design">
    <div class="title">
      <span>{{isDrafts ? '纸箱绘图草稿' :'包装设计'}}</span>
      <Button @click="tabDrafts()">{{isDrafts || curEditId ? '返回' : '草稿箱'}}</Button>
    </div>
    <div class="title-line"></div>
    <div class="edit-tab">
      <span v-if="!isDrafts || curEditId">
        <Button v-if="selectEditVal === 1" type="primary" size="small" @click="selectEdit(1)">箱型编辑</Button>
        <Button v-else size="small" @click="selectEdit(1)">箱型编辑</Button>
        <Button v-if="selectEditVal === 2" type="primary" size="small" @click="selectEdit(2)">素材编辑</Button>
        <Button v-else size="small" @click="selectEdit(2)">素材编辑</Button>
      </span>
      <div v-else class="drafts-opr">
        <Checkbox v-model="draftsAll">全选</Checkbox>
        <Button type="error" ghost @click="removeDesignDrafts">删除</Button>
      </div>
    </div>
    <!-- 编辑区域 -->
    <div class="edit-area" v-if="!isDrafts || curEditId">
      <div class="unit-setting">
        <!-- 箱型编辑 -->
        <Form
          ref="formValidate"
          v-show="selectEditVal === 1"
          :model="formValidate"
          :rules="ruleValidate"
          :label-width="0"
        >
          <FormItem label>
            <div>选择箱型</div>
            <Select
              v-model="formValidate.boxTypeId"
              @change="boxTypeIdChange"
              :disabled="Boolean(curEditId)"
              placeholder="请选择纸箱箱型"
            >
              <Option
                v-for="item in boxArr"
                :key="item.boxCode"
                :value="item.boxCode"
              >{{item.boxName}}</Option>
            </Select>
          </FormItem>
          <FormItem label>
            <div>选择楞型</div>
            <Select
              v-model="formValidate.lengxingId"
              @on-change="lengxingIdChange"
              placeholder="请选择纸箱楞型"
            >
              <Option
                v-for="item in lengxingArr"
                :key="item.materialtypeId"
                :value="item.materialtypeId"
              >{{item.materialtypeName}}</Option>
            </Select>
          </FormItem>
          <FormItem label>
            <div>厚度（单位/mm）</div>
            <Input v-model="formValidate.thickness" disabled placeholder="楞型厚度由纸箱楞型自动生成"></Input>
          </FormItem>
          <FormItem label>
            <div>选择尺寸类型</div>
            <Select v-model="formValidate.gender" disabled placeholder="选择尺寸类型">
              <Option value="ncc">内尺寸</Option>
            </Select>
          </FormItem>
          <FormItem label prop="long">
            <div class="require-Field">尺寸（单位/mm）：</div>
            <div class="ipt-group">
              <InputNumber v-model="formValidate.long" :max="9999" placeholder="长"></InputNumber>
              <InputNumber v-model="formValidate.wide" :max="9999" placeholder="宽"></InputNumber>
              <InputNumber v-model="formValidate.high" :max="9999" placeholder="高"></InputNumber>
            </div>
          </FormItem>
        </Form>
        <!-- 素材编辑 -->
        <div class v-show="selectEditVal === 2">
          <!-- 素材选择 -->
          <Select
            v-model="fodderType"
            style="width:120px;margin-bottom: 20px;"
            clearable
            @on-change="getFodderListFn"
            placeholder="全部"
          >
            <Option value="0">图片</Option>
            <Option value="1">标识</Option>
            <Option value="2">LOGO</Option>
            <Option value="3">其他</Option>
          </Select>
          <Carousel v-model="sourceImg" dots="none" arrow="never">
            <CarouselItem v-for="(item,index) in fodderList" :key="index">
              <div class="carousel-box">
                <div
                  class="icon-block"
                  v-for="(el,idx) in item"
                  :key="idx"
                  @click="selFodderImage(el)"
                >
                  <img :src="'data:image/Jpeg;base64,' + el.fodderImage" alt srcset />
                  <!-- <img :src="'data:image/Jpeg;base64,' + boxUrl" alt srcset /> -->
                </div>
              </div>
            </CarouselItem>
          </Carousel>
          <div class="carousel-operate">
            <Button icon="ios-arrow-back" @click="arrowBack"></Button>
            <Button>{{sourceImg + 1}}</Button>
            <Button icon="ios-arrow-forward" @click="arrowForward"></Button>
          </div>
          <!-- 素材缩放 -->
          <div style="margin-top: 20px">素材缩放</div>
          <div class="ipt-group material-zoom">
            <Button @click="setIconSize(200)">大</Button>
            <Button @click="setIconSize(150)">中</Button>
            <Button @click="setIconSize(100)">小</Button>
          </div>
          <!-- 素材尺寸 -->
          <div style="margin-top: 20px">素材尺寸</div>
          <div class="ipt-group material-size">
            <InputNumber v-model="sourceLong" :max="800" :min="1" id="sourceLong" placeholder="长" />
            <InputNumber v-model="sourceWide" :max="400" :min="1" id="sourceWide" placeholder="宽" />
            <div>
              <!-- <Button type="primary" @click="sourceConfirm" style="margin-top: 10px">确定</Button> -->
              <Button type="primary" @click="sourceReset" style="margin-top: 10px;">重置</Button>
            </div>
          </div>
        </div>
      </div>
      <!-- 箱型展示 -->
      <div ref="boxDisplay" class="box-display">
        <div class="box-box boundary-area" v-show="formValidate.boxTypeId">
          <div
            class="mapping-icon"
            :class="{'active': item.fodderId === curFodderId}"
            @click="selMappingIcon(item.fodderId)"
            v-for="item in iconAggregate"
            :key="item.fodderId"
            :id="item.fodderId"
            v-show="fodderIds.indexOf(item.fodderId) > -1 && selectEditVal === 2"
            :style="item.mappingIconStyle"
          ></div>
          <!-- <div class="mapping-icon" v-show="curFodderImg" :style="mappingIconStyle"></div> -->
          <!-- <img :src="'data:image/Jpeg;base64,' + boxUrl" alt srcset /> -->
          <!--重写箱型展示 -->
          <ManualBoxImg :formValidate="formValidate" ref="manualBoxImg"></ManualBoxImg>
        </div>
      </div>
    </div>
    <!-- 草稿箱模块 -->
    <div class="drafts-module" v-else>
      <Row :gutter="30">
        <Col span="6" v-for="item in draftsList" :key="item.draftId" class="drafts-item">
          <div
            class="drafts-box"
            :class="{'active': curDraftsIds.indexOf(item.draftId) > -1}"
            @click.stop="selCurDrafts(item)"
          >
            <div class="drafts-img">
              <img :src="'data:image/Jpeg;base64,' + item.boxImageurl" alt srcset width="210" />
            </div>
            <div class="drafts-info">
              <div class="name">{{item.boxName}}</div>
              <div class="other">
                <span>
                  <span>{{item.length}}</span>
                  <span>*{{item.width}}</span>
                  <span>*{{item.height}}</span>
                  <span>mm</span>
                </span>
                <span>{{item.createTime}}</span>
              </div>
              <div class="btn-module">
                <Button class="edit-btn" @click="editDrafts(item)">编辑</Button>
                <Button type="error" ghost @click="removeSingleDrafts(item)">删除</Button>
              </div>
            </div>
            <!--  -->
            <Checkbox v-model="item.checked" class="drafts-check"></Checkbox>
          </div>
        </Col>
      </Row>
    </div>
    <div class="bottom-operate">
      <div class="bottom-operate-box" v-if="!isDrafts || curEditId">
        <Button @click="downloadImg">生成图片</Button>
        <Button @click="generatePDF" v-show="selectEditVal === 2">生成PDF</Button>
        <Button @click="handleSubmit('formValidate')" v-show="selectEditVal === 1">生成CAD图纸</Button>
        <Button @click="SaveDraft('formValidate')">保存草稿</Button>
      </div>
      <div class="page-box" v-else>
        <Page
          :total="totalPage"
          show-total
          show-sizer
          :page-size-opts="[8,16,32,48]"
          :page-size="8"
          @on-change="pageChange"
          @on-page-size-change="onPageSizeChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  // getPackageAttributeDropBoxs,
  getBoxTypeSupportList,
  getFodderList,
  // generateCadByBox,
  getLengxingrList,
  designDraftsAdd,
  designDraftsList,
  designDraftsRemove,
  designDraftsEdit
} from '@/api/packing'
import qs from 'qs'
// import html2canvas from 'html2canvas'
import ManualBoxImg from './components/ManualBoxImg.vue'
import htmlToImg from '@/utils/htmlToImg'
import htmlToPdf from '@/utils/htmlToPdf'
import { on, off } from '@/utils/dom'
export default {
  data () {
    const validateLong = (rule, value, callback) => {
      if (
        value === '' ||
        this.formValidate.wide === '' ||
        this.formValidate.high === ''
      ) {
        callback(new Error('必填'))
      } else if (
        value > 9999 ||
        this.formValidate.wide > 9999 ||
        this.formValidate.high > 9999
      ) {
        callback(new Error('最大值为9999'))
      } else if (
        value < 100 ||
        this.formValidate.wide < 100 ||
        this.formValidate.high < 100
      ) {
        callback(new Error('最小值为100'))
      } else {
        callback()
      }
    }
    return {
      selectEditVal: 1,
      fodderType: '',
      sourceImg: 0,
      formValidate: {
        lengxingId: '',
        thickness: '',
        gender: 'ncc',
        boxTypeId: '',
        long: 100,
        wide: 100,
        high: 100
      },
      // 是否为草稿箱页面
      isDrafts: false,
      // 楞型下拉
      lengxingArr: [],
      // 箱型下拉
      boxArr: [],
      // 移动图标的样式
      mappingIconStyle: {
        width: '100px',
        height: '100px',
        'background-size': '100px 100px',
        'background-image': ''
      },
      // 素材尺寸-长
      sourceLong: 120,
      // 素材尺寸-宽
      sourceWide: 120,
      // 刻度尺横竖类型
      rulerLongType: 'vertical',
      rulerWideType: 'transverse',
      rulerHighType: 'vertical',
      // 刻度尺长度
      rulerLongVal: 100,
      rulerWideVal: 200,
      rulerHighVal: 200,
      // 素材展示数组
      fodderList: [],
      curFodderImg: '',
      rulerLongStyle: {
        top: 0,
        left: 0
      },
      rulerWideStyle: {
        top: 0,
        left: 0
      },
      rulerHighStyle: {
        top: 0,
        left: 0
      },
      ruleValidate: {
        long: [
          // { required: true, message: '必填', trigger: 'blur' },
          { validator: validateLong, trigger: ['blur', 'change'] }
        ]
      },
      // 全选草稿箱
      draftsAll: false,
      // 多素材集合
      iconAggregate: [],
      // 已展示图标集合
      fodderIds: [],
      // 当前选中的图标id
      curFodderId: '',
      draftsList: [],
      // 当前草稿箱id集合
      curDraftsIds: [],
      totalPage: 0,
      //
      curEditId: false,
      pageNum: 1,
      pageSize: 8
    }
  },
  components: {
    ManualBoxImg: ManualBoxImg
  },
  computed: {
    boxUrl () {
      var url = this.boxArr.filter(
        (item) => item.boxCode === this.formValidate.boxTypeId
      )
      return url && url[0] && url[0].boxImageurl
    }
  },
  watch: {
    'formValidate.boxTypeId': {
      handler (val) {
        this.boxTypeIdChange(val)
      },
      immediate: true
    },
    sourceLong: {
      handler (val) {
        if (val) {
          this.iconAggregate.forEach((item, index) => {
            if (item.fodderId === this.curFodderId) {
              item.mappingIconStyle = this.sourceConfirm(item.fodderImage)
            }
          })
        }
      }
    },
    sourceWide: {
      handler (val) {
        if (val) {
          this.iconAggregate.forEach((item, index) => {
            if (item.fodderId === this.curFodderId) {
              item.mappingIconStyle = this.sourceConfirm(item.fodderImage)
            }
          })
        }
      }
    },
    draftsAll: {
      handler (val) {
        if (val) {
          this.curDraftsIds = this.draftsList.map(item => {
            return item.draftId
          })
        } else {
          this.curDraftsIds = []
        }
      }
    },
    curDraftsIds: {
      handler (val) {
        this.draftsList.forEach(item => {
          item.checked = this.curDraftsIds.indexOf(item.draftId) > -1
        })
      }
    }
  },
  methods: {
    handleSubmit (name) {
      // this.$refs[name].validate((valid) => {
      //   if (valid) {
      //     this.generateCadByBoxFn()
      //   }
      // })
      var long = this.formValidate.long
      var wide = this.formValidate.wide
      var high = this.formValidate.high
      if (
        long === '' ||
        wide === '' ||
        high === '' ||
        long > 9999 ||
        wide > 9999 ||
        high > 9999 ||
        long < 100 ||
        wide < 100 ||
        high < 100
      ) {
        return
      }
      this.generateCadByBoxFn()
    },
    handleReset (name) {
      this.$refs[name].resetFields()
    },
    selectEdit (val) {
      this.selectEditVal = val
    },
    // 左滑
    arrowBack () {
      if (this.sourceImg > 0) this.sourceImg--
    },
    // 右滑
    arrowForward () {
      if (this.sourceImg < this.fodderList.length - 1) this.sourceImg++
    },
    async init () {
      // 获取楞型
      var res = await getLengxingrList()
      this.lengxingArr = res && res.data
      this.formValidate.lengxingId =
        this.lengxingArr[0] && this.lengxingArr[0].materialtypeId
      this.formValidate.thickness =
        this.lengxingArr[0] && this.lengxingArr[0].materialtypeThickness
      // 获取箱型
      var ret = await getBoxTypeSupportList()
      this.boxArr = ret && ret.data
      this.formValidate.boxTypeId =
        ret && ret.data && ret.data[0] && ret.data[0].boxCode
      this.getFodderListFn()
    },
    async getFodderListFn () {
      var res = await getFodderList({ fodderType: this.fodderType, pageNum: 1, pageSize: 9999 })
      var oneArr =
        res &&
        res.rows.map((item) => {
          return {
            fodderImage: item.fodderImage,
            fodderId: item.fodderId
          }
        })
      this.fodderList = this.arrChange(12, oneArr)
    },
    // 入参 num:需要转换成几维数组，arr:需要转化的数组
    // 出参 转换后的数组
    arrChange (num, arr) {
      const newArr = []
      while (arr.length > 0) {
        newArr.push(arr.splice(0, num))
      }
      return newArr
    },
    // 生成图片
    downloadImg () {
      // html2canvas(this.$refs.boxDisplay).then(function (canvas) {
      //   console.log(canvas) // 生成canvas画布
      //   const base64Str = canvas.toDataURL()
      //   console.log(base64Str) // 将canvas转为base64图片
      //   const aLink = document.createElement('a')
      //   aLink.style.display = 'none'
      //   aLink.href = base64Str
      //   aLink.download = new Date().getTime() + '.jpg'
      //   // 触发点击-然后移除
      //   document.body.appendChild(aLink)
      //   aLink.click()
      //   document.body.removeChild(aLink)
      // })
      htmlToImg.createImg(document.querySelector('.boundary-area'))
    },
    // 素材尺寸确认
    sourceConfirm (curFodderImg) {
      var width =
        (this.sourceLong - this.formValidate.thickness) *
          this.$refs.manualBoxImg.sizeFactor +
        this.formValidate.thickness
      var height =
        (this.sourceWide - this.formValidate.thickness) *
          this.$refs.manualBoxImg.sizeFactor +
        this.formValidate.thickness
      return {
        sourceLong: this.sourceLong,
        sourceWide: this.sourceWide,
        width: width + 'px',
        height: height + 'px',
        'background-size': width + 'px ' + height + 'px',
        'background-image': "url('data:image/Jpeg;base64," + curFodderImg + "')"
      }
    },
    // 素材尺寸重置
    sourceReset () {
      this.sourceLong = 120
      this.sourceWide = 120
    },
    // 素材清空
    curFodderImgClear (event) {
      if (event.key === 'Delete') {
        this.curFodderImg = ''
        this.iconAggregate = this.iconAggregate.filter(
          (item) => item.fodderId !== this.curFodderId
        )
        this.fodderIds = this.fodderIds.filter(
          (item) => item !== this.curFodderId
        )
      }
    },
    boxTypeIdChange (val) {
      if (!val) return
      var curArr = this.boxArr.filter((item) => item.boxCode === val)
      if (curArr && curArr[0].boxCode) {
        switch (curArr[0].boxCode) {
          case '0200':
            this.rulerLongType = 'transverse'
            this.rulerWideType = 'transverse'
            this.rulerHighType = 'vertical'
            // 长
            this.rulerLongStyle = {
              top: '30px',
              left: '22px'
            }
            this.rulerLongVal = 118
            // 宽
            this.rulerWideStyle = {
              top: '50px',
              left: '145px'
            }
            this.rulerWideVal = 75
            // 高
            this.rulerHighStyle = {
              top: '22px',
              left: '290px'
            }
            this.rulerHighVal = 50
            this.formValidate.long = 500
            this.formValidate.wide = 400
            this.formValidate.high = 300
            break
          case '0201':
            this.rulerLongType = 'transverse'
            this.rulerWideType = 'transverse'
            this.rulerHighType = 'vertical'
            // 长
            this.rulerLongStyle = {
              top: '60px',
              left: '22px'
            }
            this.rulerLongVal = 126
            // 宽
            this.rulerWideStyle = {
              top: '75px',
              left: '150px'
            }
            this.rulerWideVal = 75
            // 高
            this.rulerHighStyle = {
              top: '45px',
              left: '290px'
            }
            this.rulerHighVal = 50
            this.formValidate.long = 500
            this.formValidate.wide = 400
            this.formValidate.high = 300
            break
          case '0203':
            this.rulerLongType = 'transverse'
            this.rulerWideType = 'transverse'
            this.rulerHighType = 'vertical'
            // 长
            this.rulerLongStyle = {
              top: '92px',
              left: '18px'
            }
            this.rulerLongVal = 100
            // 宽
            this.rulerWideStyle = {
              top: '110px',
              left: '122px'
            }
            this.rulerWideVal = 58
            // 高
            this.rulerHighStyle = {
              top: '85px',
              left: '230px'
            }
            this.rulerHighVal = 45
            this.formValidate.long = 497
            this.formValidate.wide = 382
            this.formValidate.high = 430
            break
          case '0204':
            this.rulerLongType = 'transverse'
            this.rulerWideType = 'transverse'
            this.rulerHighType = 'vertical'
            // 长
            this.rulerLongStyle = {
              top: '145px',
              left: '22px'
            }
            this.rulerLongVal = 163
            // 宽
            this.rulerWideStyle = {
              top: '170px',
              left: '190px'
            }
            this.rulerWideVal = 93
            // 高
            this.rulerHighStyle = {
              top: '128px',
              left: '370px'
            }
            this.rulerHighVal = 50
            this.formValidate.long = 497
            this.formValidate.wide = 382
            this.formValidate.high = 430
            break
          default:
            break
        }
      }
    },
    lengxingIdChange () {
      var curArr = this.lengxingArr.filter(
        (item) => item.materialtypeId === this.formValidate.lengxingId
      )
      this.formValidate.thickness = curArr && curArr[0].materialtypeThickness
    },
    // 选择素材
    selFodderImage (el) {
      this.curFodderId = el.fodderId
      if (this.fodderIds.indexOf(el.fodderId) > -1) {
      } else {
        // var width = (this.sourceLong - this.formValidate.thickness) * this.$refs.manualBoxImg.sizeFactor + this.formValidate.thickness
        // var height = (this.sourceWide - this.formValidate.thickness) * this.$refs.manualBoxImg.sizeFactor + this.formValidate.thickness
        this.fodderIds.push(el.fodderId)
        this.iconAggregate.push({
          fodderId: el.fodderId,
          fodderImage: el.fodderImage,
          mappingIconStyle: this.sourceConfirm(el.fodderImage)
        })
        this.$nextTick(() => {
          var mappingIcon = document.getElementById(el.fodderId)
          this.setIconDrag(mappingIcon)
        })
      }
      this.curFodderImg = el.fodderImage
    },
    async generateCadByBoxFn () {
      var boxTypeIdArr = this.boxArr.filter(
        (item) => item.boxCode === this.formValidate.boxTypeId
      )
      var params = qs.stringify({
        boxTypeId: boxTypeIdArr && boxTypeIdArr[0] && boxTypeIdArr[0].id,
        height: this.formValidate.high,
        length: this.formValidate.long,
        lengxingId: this.formValidate.lengxingId,
        width: this.formValidate.wide
      })
      // window.open(document.location.protocol + '//222.240.49.66:18076/dist/generateCadByBox?' + params)
      window.open(
        document.location.protocol +
          '//' +
          document.location.host +
          '/dist/generateCadByBox?' +
          params
      )
    },
    // 设置素材大小
    setIconSize (val) {
      this.sourceLong = val
      this.sourceWide = val
    },
    generatePDF () {
      htmlToPdf.downloadPDF(
        document.querySelector('.boundary-area'),
        'logo',
        12345678
      )
    },
    // 保存草稿
    SaveDraft () {
      var boxTypeIdArr = this.boxArr.filter(
        (item) => item.boxCode === this.formValidate.boxTypeId
      )
      var params = {
        boxTypeId: boxTypeIdArr && boxTypeIdArr[0] && boxTypeIdArr[0].id,
        height: this.formValidate.high,
        length: this.formValidate.long,
        lengxingId: this.formValidate.lengxingId,
        width: this.formValidate.wide,
        designFodderList: this.getDraftIcon()
      }
      if (this.curEditId) {
        params.draftId = this.curEditId
        designDraftsEdit(params).then((res) => {
          console.log(res)
          if (res.code === 0) {
            this.$Message.success('保存草稿成功')
            this.curEditId = false
            this.getDraftsList()
          }
        })
      } else {
        designDraftsAdd(params).then((res) => {
          console.log(res)
          if (res.code === 0) {
            this.$Message.success('保存草稿成功')
          }
        })
      }
    },
    getDraftIcon () {
      var draftIcon = []
      this.iconAggregate.forEach(item => {
        var curDom = document.getElementById(item.fodderId)
        console.log(curDom.style.left)
        console.log(item)
        const param = {
          fodderId: item.fodderId, // '素材ID',
          fodderImage: item.fodderImage, // '素材图片路径'
          sourceLong: item.mappingIconStyle.sourceLong, // 素材长度
          sourceWide: item.mappingIconStyle.sourceWide, // 素材宽度
          leftLength: curDom.style.left.replace('px', ''), // '素材距离左边的位置'
          topLength: curDom.style.top.replace('px', '') // '素材距离顶部的位置'
        }
        draftIcon.push(param)
      })
      console.log(draftIcon)
      return draftIcon
    },
    // 草稿箱切换
    tabDrafts () {
      // 如果当前为编辑状态，那就返回到草稿箱
      if (this.curEditId) {
        this.curEditId = false
        return
      }
      this.isDrafts = !this.isDrafts
      if (!this.isDrafts) return
      this.getDraftsList()
    },
    getDraftsList () {
      designDraftsList({ pageNum: this.pageNum, pageSize: this.pageSize }).then((res) => {
        this.draftsList = res && res.rows
        this.totalPage = res && res.total
        this.draftsList.forEach((item) => {
          item.checked = false
        })
      })
    },
    // 设置拖拽功能
    setIconDrag (mappingIcon) {
      this.$nextTick(() => {
        var boundaryArea = document.querySelector('.boundary-area')
        // var mappingIcon = document.querySelector('.mapping-icon')

        mappingIcon.onmousedown = function (e) {
          e = e || window.event
          var crt = e || window.event
          var left = e.target.offsetLeft
          var top = e.target.offsetTop
          var nowX = e.clientX
          var nowY = e.clientY
          e.target.style.cursor = 'move'
          // e.target.style.border = '1px solid #ee7e36'
          e.target.style.borderRadius = '4px'

          document.onmousemove = function (e) {
            var resultX = left + e.clientX - nowX
            var resultY = top + e.clientY - nowY
            if (resultX < 0) {
              resultX = 0
            } else if (
              resultX >
              boundaryArea.clientWidth - mappingIcon.offsetWidth
            ) {
              resultX = boundaryArea.clientWidth - mappingIcon.offsetWidth
            }
            if (resultY < 0) {
              resultY = 0
            } else if (
              resultY >
              boundaryArea.clientHeight - mappingIcon.offsetHeight
            ) {
              resultY = boundaryArea.clientHeight - mappingIcon.offsetHeight
            }
            crt.target.style.left = resultX + 'px'
            crt.target.style.top = resultY + 'px'
          }
          document.onmouseup = function () {
            document.onmousemove = null
            // mappingIcon.style.cursor = 'default'
            // mappingIcon.style.border = 'none'
          }
        }
      })
    },
    // 选中图标
    selMappingIcon (fodderId) {
      this.curFodderId = fodderId
      this.iconAggregate.forEach((item) => {
        if (item.fodderId === this.curFodderId) {
          this.sourceLong = item.mappingIconStyle.sourceLong
          this.sourceWide = item.mappingIconStyle.sourceWide
        }
      })
    },
    handleDocumentClick (e) {
      var id = e.target.id
      var className = e.target.className
      if (
        className === 'box-display' ||
        className === 'box-box boundary-area' ||
        className === 'mapping-icon' ||
        className === 'manual-box-img' ||
        e.path[1].className === 'manual-box-img'
      ) {
        this.curFodderId = Number(id)
      }
    },
    // 选中当前草稿箱
    selCurDrafts (data) {
      if (this.curDraftsIds.indexOf(data.draftId) > -1) {
        this.curDraftsIds = this.curDraftsIds.filter(
          (item) => item !== data.draftId
        )
      } else {
        this.curDraftsIds.push(data.draftId)
      }
    },
    // 删除草稿箱
    removeDesignDrafts () {
      if (this.curDraftsIds.length <= 0) {
        this.$Message.warning('请选择要删除的数据')
      }
      designDraftsRemove({ ids: this.curDraftsIds.join(',') }).then((res) => {
        this.getDraftsList()
        this.$Message.success('删除成功')
      })
    },
    // 删除单个草稿箱
    removeSingleDrafts (data) {
      designDraftsRemove({ ids: data.draftId }).then((res) => {
        this.getDraftsList()
      })
    },
    // 编辑草稿箱
    editDrafts (data) {
      this.iconAggregate = []
      this.fodderIds = []
      this.curEditId = data.draftId
      this.formValidate.lengxingId = data.lengxingId
      this.formValidate.long = data.length
      this.formValidate.wide = data.width
      this.formValidate.high = data.height
      this.formValidate.boxTypeId = data.boxName.slice(0, 4)
      var designFodderVOList = data.designFodderVOList
      this.$nextTick(() => {
        designFodderVOList.forEach(item => {
          var width = (item.sourceLong - this.formValidate.thickness) * this.$refs.manualBoxImg.sizeFactor + this.formValidate.thickness
          var height = (item.sourceWide - this.formValidate.thickness) * this.$refs.manualBoxImg.sizeFactor + this.formValidate.thickness
          this.iconAggregate.push({
            fodderId: item.fodderId,
            fodderImage: item.fodderImage,
            mappingIconStyle: {
              sourceLong: item.sourceLong,
              sourceWide: item.sourceWide,
              width: width + 'px',
              height: height + 'px',
              'background-size': width + 'px ' + height + 'px',
              'background-image': "url('data:image/Jpeg;base64," + item.fodderImage + "')"
            }
          })
          this.fodderIds.push(item.fodderId)
          this.$nextTick(() => {
            var mappingIcon = document.getElementById(item.fodderId)
            this.setIconDrag(mappingIcon)
            mappingIcon.style.left = (item.leftLength || 0) + 'px'
            mappingIcon.style.top = (item.topLength || 0) + 'px'
            // mappingIcon.style.display = 'block'
          })
        })
      })
    },
    // 页码改变的回调，返回改变后的页码
    pageChange (val) {
      this.pageNum = val
      this.getDraftsList()
    },
    // 切换每页条数时的回调，返回切换后的每页条数
    onPageSizeChange (val) {
      this.pageNum = 1
      this.pageSize = val
      this.getDraftsList()
    },
    mouseScrollEvent (e) {
      const evt = e || window.event
      evt.preventDefault()
      if (evt.deltaY > 0) {
        console.log('向下滚动')
      } else {
        console.log('向上滚动')
      }
    }
  },
  mounted () {
    this.init()
    this.$nextTick(() => {
      // var boundaryArea = document.querySelector('.boundary-area')
      // var mappingIcon = document.querySelector('.mapping-icon')

      // mappingIcon.onmousedown = function (e) {
      //   e = e || window.event
      //   var crt = e || window.event
      //   var left = e.target.offsetLeft
      //   var top = e.target.offsetTop
      //   var nowX = e.clientX
      //   var nowY = e.clientY
      //   e.target.style.cursor = 'move'
      //   e.target.style.border = '1px solid #ee7e36'
      //   e.target.style.borderRadius = '4px'

      //   document.onmousemove = function (e) {
      //     var resultX = left + e.clientX - nowX
      //     var resultY = top + e.clientY - nowY
      //     if (resultX < 0) {
      //       resultX = 0
      //     } else if (
      //       resultX >
      //       boundaryArea.clientWidth - mappingIcon.offsetWidth
      //     ) {
      //       resultX = boundaryArea.clientWidth - mappingIcon.offsetWidth
      //     }
      //     if (resultY < 0) {
      //       resultY = 0
      //     } else if (
      //       resultY >
      //       boundaryArea.clientHeight - mappingIcon.offsetHeight
      //     ) {
      //       resultY = boundaryArea.clientHeight - mappingIcon.offsetHeight
      //     }
      //     crt.target.style.left = resultX + 'px'
      //     crt.target.style.top = resultY + 'px'
      //   }
      // }
      // document.onmouseup = function () {
      //   document.onmousemove = null
      //   mappingIcon.style.cursor = 'default'
      //   mappingIcon.style.border = 'none'
      // }
      on(document, 'keydown', this.curFodderImgClear)
      document.addEventListener('click', this.handleDocumentClick) // 给document添加一个点击事件
      window.addEventListener('wheel', this.mouseScrollEvent)
    })
  },
  destroyed () {
    off(document, 'keydown', this.curFodderImgClear)
    window.removeEventListener('wheel', this.mouseScrollEvent)
  }
}
</script>
<style lang="scss" scoped>
.pack-design {
  margin: 0 auto;
  height: calc(100vh - 90px);
  width: 1100px;
  box-sizing: border-box;
  .title {
    font-size: 24px;
    color: #333333;
    height: 70px;
    line-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ivu-btn {
      width: 120px;
      border: 1px solid #67c5ec;
      font-size: 14px;
      margin-left: 10px;
      color: #67c5ec;
    }
  }
  .title-line {
    width: 100%;
    height: 1px;
    background: #67c5ec;
  }
  .edit-tab {
    margin-top: 20px;
    margin-bottom: 20px;
    .ivu-btn {
      border-radius: 12px;
      padding: 0 27px;
      margin-right: 16px;
    }
    .drafts-opr {
      text-align: right;
      .ivu-btn-ghost.ivu-btn-error {
        background: rgba(247, 86, 78, 0.2);
        border: 1px solid #f7564e;
        border-radius: 2px;
        margin-left: 30px;
      }
    }
  }
  .edit-area {
    height: calc(100% - 215px);
    display: flex;
    .unit-setting {
      width: 260px;
      height: 100%;
      background: #fff;
      border-radius: 3px;
      margin-right: 20px;
      padding: 20px;
      box-sizing: border-box;
      overflow: auto;
      .demo-carousel {
        height: 308px;
      }
    }
    .box-display {
      width: 820px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .mapping-icon {
        position: absolute;
        left: 0;
        top: 0;
        // background-image: url('http://t15.baidu.com/it/u=1967314571,3880686513&fm=224&app=112&f=JPEG?w=500&h=281');
        background-repeat: no-repeat;
        z-index: 9999;
      }
      .mapping-icon.active {
        border: 2px solid #ee7e36;
        box-sizing: border-box;
      }
      .box-box {
        position: relative;
        padding: 30px 50px;
        user-select: none;
      }
      .ruler {
        position: absolute;
        text-align: center;
        i {
          color: #ee7e36;
        }
        .ruler-box {
          position: relative;
          color: rgb(238, 126, 54);
          span {
            font-size: 16px;
          }
          i.arrow-back {
            position: absolute;
            left: -10px;
            top: -13px;
          }
          i.arrow-forward {
            position: absolute;
            right: -10px;
            top: -13px;
          }
          i.arrow-up {
            position: absolute;
            left: -11px;
            top: -9px;
          }
          i.arrow-down {
            position: absolute;
            bottom: -9px;
            left: -11px;
          }
        }
      }
      .ruler.vertical {
        .ruler-line {
          width: 0;
          border: 1px solid #ee7e36;
          position: absolute;
          bottom: 0;
          height: 100%;
          background: #ee7e36;
        }
        span {
          margin-left: 10px;
        }
      }
      .ruler.transverse {
        .ruler-line {
          width: 100%;
          border: 1px solid rgb(238, 126, 54);
          position: absolute;
          bottom: 0;
          height: 0;
          background: #ee7e36;
        }
      }
    }
    .carousel-operate {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 30px;
      .ivu-btn {
        margin: 0 5px;
      }
    }
    .ipt-group {
      display: flex;
      justify-content: space-between;
      padding: 10px 0 0 0;
      .ivu-input-wrapper {
        width: 66px;
      }
      .ivu-input-number {
        width: 68px;
      }
    }
    .ipt-group.material-zoom {
      .ivu-btn-default {
        width: 66px;
      }
    }
    .ipt-group.material-size {
      flex-wrap: wrap;
      .ivu-input-wrapper {
        width: 105px;
      }
      .ivu-input-number {
        width: 105px;
      }
    }
    .ivu-carousel {
      width: 220px;
      max-height: 340px;
      margin: 0 auto;
      .carousel-box {
        display: flex;
        flex-wrap: wrap;
        .icon-block {
          width: 70px;
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          border: 1px solid #dfdfdf;
          border-radius: 5px;
          margin-bottom: 10px;
          img {
            width: 60px;
            height: 60px;
            cursor: pointer;
          }
        }
        .icon-block:nth-child(3n -1) {
          margin: 0 5px;
        }
      }
    }
  }
  .bottom-operate {
    background: #fff;
    height: 80px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    .bottom-operate-box {
      margin: 0 auto;
      width: 1100px;
      height: 100%;
      line-height: 80px;
      text-align: right;
      padding-right: 130px;
      box-sizing: border-box;
      .ivu-btn {
        width: 120px;
        border: 1px solid #67c5ec;
        font-size: 14px;
        margin-left: 10px;
        color: #67c5ec;
      }
    }
  }
  .require-Field::before {
    content: '*';
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 12px;
    color: #ed4014;
  }
  .ivu-select-disabled .ivu-select-selection {
    color: #333333;
  }
  // 草稿箱模块的样式
  .drafts-module {
    background-color: #fff;
    height: calc(100% - 215px);
    width: 100%;
    padding: 30px;
    overflow-y: auto;
    .ivu-col .drafts-box {
      height: 288px;
      border: 1px solid #f1f2f3;
      border-radius: 4px;
      box-sizing: border-box;
      cursor: pointer;
      position: relative;
      .drafts-img {
        height: 170px;
        border-bottom: 1px solid #f1f2f3;
        background-color: rgb(237, 238, 239);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .drafts-info {
        padding: 15px;
        box-sizing: border-box;
        .name {
          font-size: 20px;
        }
        .other {
          font-size: 12px;
          display: flex;
          justify-content: space-between;
        }
      }
      .drafts-check {
        position: absolute;
        top: 10px;
        left: 10px;
      }
    }
    .ivu-col .drafts-box:hover,
    .ivu-col .drafts-box.active {
      border: 2px solid #67c5ec;
      box-shadow: 0px 2px 6px 4px rgba(103,197,236,0.25);
    }
    .drafts-item {
      margin-bottom: 12px;
    }
    .btn-module {
      text-align: right;
      margin-top: 8px;
      .edit-btn.ivu-btn {
        border: 1px solid #67c5ec;
        font-size: 14px;
        border-radius: 2px;
        margin-right: 10px;
        color: #67c5ec;
      }
      .ivu-btn-error {
        background: rgba(247, 86, 78, 0.2);
        border: 1px solid #f7564e;
        border-radius: 2px;
      }
    }
  }
  .page-box {
    margin: 0 auto;
    width: 1100px;
    height: 100%;
    line-height: 80px;
    text-align: center;
    box-sizing: border-box;
  }
}
</style>
<style lang="scss" scoped>
.manual-box-img {
  position: relative;
  .line {
    position: absolute;
  }

  .line.horizontal {
    height: 0;
    border-top: 1px solid #000000;
    &.dotted_line {
      border-top: 1px dashed #000000;
    }
  }
  .line.vertical {
    width: 0;
    border-left: 1px solid #000000;
    &.dotted_line {
      border-left: 1px dashed #000000;
    }
  }
  .scale {
    position: absolute;
    color: #ee7e36;
    text-align: center;
    .ruler-line {
      width: 100%;
      border: 1px solid rgb(238, 126, 54);
      position: absolute;
      bottom: 0;
      background: #ee7e36;
    }
    i.arrow-back {
      position: absolute;
      top: -13px;
      left: -9px;
    }
    i.arrow-forward {
      position: absolute;
      right: -9px;
      top: -13px;
    }
  }
  .scale.scale-high {
    .ruler-line {
      height: 100%;
      width: 0;
      border: 1px solid rgb(238, 126, 54);
      position: absolute;
      bottom: 0;
      background: #ee7e36;
    }
    i.arrow-up {
      position: absolute;
      top: -8px;
      left: -11px;
    }
    i.arrow-down {
      position: absolute;
      left: -11px;
      bottom: -8px;
    }
    span {
      margin-left: 5px;
    }
  }
}
</style>
<style lang="scss">
.pack-design {
  .ivu-input[disabled],
  fieldset[disabled] .ivu-input,
  .ivu-select-disabled .ivu-select-selection {
    color: #333333;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: #e1e1e1;
  }

  ::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background-color: #fff;
  }
}
</style>

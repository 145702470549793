<template>
  <div style="background:#ffffff;">
    <div class="first-screen">
      <div class="container">
        <div class="inner">
          <div class="text">
            <h1 class="animate__animated animate__fadeInDown">关于我们/About Us</h1>

          </div>
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="section about-wrap">
      <div class="container">
        <div class="container-inner">
          <div class="title wow  animate__fadeIn">
            <!-- 研究所介绍/Introduction of UNI-Packing -->
            <img src="@/assets/images/img_introduction.png" alt="">
          </div>
          <div class="intro" style="line-height: 30px;">
            <p>我们拥有专业的设计师，也欢迎每一位拥有包装经验的你，分享你的智慧，实现多重价值。</p>
            <p>优纳伯研究所的功能:<br>
           <span style="font-weight:bold">承接全国范围内的运输包装设计</span><br>
           选择我们，为您的产品量身设计最具性价比的运输包装方案</p>
           <p><span style="font-weight:bold">知识付费平台(www.52baozhuang.com)</span><br>
            从学习到变现，知识创造价值</p>
            <p><span style="font-weight:bold">包装软件</span><br>
           PackingGo:-款关于完整包装解决法方案(CPS)的设计软件<br>纸箱强度设计系统:一款用于快速设计纸箱材料强度的软件</p>
          <p><span style="font-weight:bold">理论研究</span><br>
                通过大量的实验，研究纸箱的抗压规律，并取得了一定的成果。</p>

          </div>
          <div class="aboutus">
            <Row>
              <Col span="12">
              <div class="aboutus-item">
                <img src="@/assets/images/bg_aboutus_01.svg" alt="" style="width:80px;position: absolute;top:0;left:0">
                <div class="aboutus-title wow  animate__fadeInDown">强大的团队</div>
                <p class="aboutus-desc wow animate__fadeInRight">
                  PackingGo判可狗是优纳伯（UNI-PACKING）旗下产品。优纳伯企业是一家专事高端制造业完整包装解决方案及产品导入的创新型企业，拥有完备的自主知识产权体系，企业内部设有独立的包装技术研究所，拥有国内一流的专业研发团队。
                </p>
              </div>
              </Col>
              <Col span="12">
              <img src="@/assets/images/img_introduction_01.png" alt="">
              </Col>
              <Col span="12">
              <img src="@/assets/images/img_introduction_02.png" alt="">
              </Col>
              <Col span="12">
              <div class="aboutus-item">
                <img src="@/assets/images/bg_aboutus_02.svg" alt="" style="width:80px;position: absolute;bottom:0;right:0">
                <div class="aboutus-title wow  animate__fadeInDown">丰富的经验</div>
                <p class="aboutus-desc wow  animate__fadeInLeft">
                  过往十年间，优纳伯向高端制造企业中国中车、南方航空和日本本田等包含不低于30家上市公司提供超过10000个运输包装解决方案，这些优化方案帮助客户实现成本收益均高于10%。</p>
              </div>
              </Col>
            </Row>
            <div style="text-align:center;margin-top: 50px;">
              <a href="http://uni-packing.com/" target="_blank" class="btn-go">前往优纳伯官网</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品功能/Functions  -->
    <div class=" section applicable">
      <div class="container">
        <div class="container-inner">
          <div class="title wow animate__fadeIn">
            <!-- 适用行业/Applicable -->
            <img src="@/assets/images/img_applicable.png" alt="">
          </div>

          <div style="margin-top: 60px;" class="wow  animate__zoomIn">
            <Row :gutter="1">
              <Col :span="8" class="applicable-item">
              <img src="@/assets/images/img_applicable_01.png" alt="">
              <p class="text">
                医疗·金融·工业制造等智能机柜
              </p>
              </Col>
              <Col :span="8" class="applicable-item">
              <img src="@/assets/images/img_applicable_02.png" alt="">
              <p class="text">
                固体·流体等流体抗外鼓包装
              </p>
              </Col>
              <Col :span="8" class="applicable-item">
              <img src="@/assets/images/img_applicable_03.png" alt="">
              <p class="text">
                军用包装
              </p>
              </Col>
              <Col :span="8" class="applicable-item">
              <img src="@/assets/images/img_applicable_04.png" alt="">
              <p class="text">
                汽车及航空部件·代步器材器具
              </p>
              </Col>
              <Col :span="8" class="applicable-item">
              <img src="@/assets/images/img_applicable_05.png" alt="">
              <p class="text">
                汽车及航空部件·代步器材器具
              </p>
              </Col>
              <Col :span="8" class="applicable-item">
              <img src="@/assets/images/img_applicable_06.png" alt="">
              <p class="text">
                ‘一高一大’整体或集体包装
              </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品特点/Features -->
    <div class=" section product-features">
      <div class="container">
        <div class="container-inner">
          <div class="title wow  animate__fadeIn">
            <!-- 研究所功能/Functions -->
            <img src="@/assets/images/img_function.png" alt="">
          </div>

          <div style="margin-top: 60px;">
            <div class="features-wrap">
              <Row>
                <Col :span="8">
                <div class="features-item" :class="{'is-active':activeIndex === 0}" @mouseenter="activeIndex = 0">
                  <div class="features-item-inner">
                    <i class="iconfont icon-about_function_01"></i>
                  </div>
                  完整包装解决方案设计
                </div>
                </Col>
                <Col :span="8">
                <div class="features-item" :class="{'is-active':activeIndex === 1}" @mouseenter="activeIndex = 1">
                  <div class="features-item-inner"> <i class="iconfont icon-about_function_02"></i></div>
                  包装行业通用软件研发
                </div>
                </Col>
                <Col :span="8">

                <div class="features-item" :class="{'is-active':activeIndex === 2}" @mouseenter="activeIndex = 2">
                  <div class="features-item-inner"> <i class="iconfont icon-about_function_03"></i></div>
                  包装案例的收集与交换
                </div>
                </Col>
                <Col :span="8">
                <div class="features-item" :class="{'is-active':activeIndex === 3}" @mouseenter="activeIndex = 3">
                  <div class="features-item-inner"> <i class="iconfont icon-about_function_04"></i></div>
                  包装成本的控制
                </div>
                </Col>
                <Col :span="8">
                <div class="features-item" :class="{'is-active':activeIndex === 4}" @mouseenter="activeIndex = 4">
                  <div class="features-item-inner"> <i class="iconfont icon-about_function_05"></i></div>
                  完整包装解决方案测试
                </div>

                </Col>
                <Col :span="8">
                <div class="features-item" :class="{'is-active':activeIndex === 5}" @mouseenter="activeIndex = 5">
                  <div class="features-item-inner"> <i class="iconfont icon-about_function_06"></i></div>
                  常年顾问
                </div>
                </Col>

              </Row>

            </div>

            <div style="height: 30px;"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 适用行业/Applicable -->
    <div class="section product-function">
      <div class="container">
        <div class="container-inner">
          <div style="margin-top: 60px;">
            <Row :gutter="50">
              <Col span="8">
              <div class="function-item wow animate__zoomIn bg1">
                <div class="pic">
                  <div class="func-icon">
                    <img src="@/assets/images/about_function_07.svg" alt="">
                  </div>
                </div>
                <div class="function-meta">
                  <div class="meta-title">方案特征</div>
                  <p class="meta-desc">
                    1.系统优化重型包装客户物流条件<br>
                    2.将重型包装产品导入集成服务商<br>
                    3.完整包装解决方案（CPS）工作法</p>
                </div>
              </div>
              </Col>
              <Col span="8">
              <div class="function-item wow animate__zoomIn">
                <div class="pic">
                  <div class="func-icon">
                    <img src="@/assets/images/about_function_08.svg" alt="">
                  </div>
                </div>
                <div class="function-meta">
                  <div class="meta-title">专业团队</div>
                  <p class="meta-desc">
                    优纳伯包装技术研究所主要成员：<br>
                    1.两位资深专家<br>
                    2.五名专业包装工程师
                  </p>
                </div>
              </div>
              </Col>
              <Col span="8">
              <div class="function-item wow animate__zoomIn bg2">
                <div class="pic">
                  <div class="func-icon">
                    <img src="@/assets/images/about_function_09.svg" alt="">
                  </div>
                </div>
                <div class="function-meta">
                  <div class="meta-title">研究领域</div>
                  <p class="meta-desc">
                    1.CPS法行业通用软件研发<br>
                    2.完整包装解决方案（CPS）输出<br>
                    3.基于企业深度成本降解的供应链集成策略研究
                  </p>
                </div>
              </div>
              </Col>
            </Row>
          </div>

        </div>
      </div>
    </div>
    <!-- 合作伙伴/Customers -->
    <div class="section customers">
      <div class="container">
        <div class="container-inner">
          <div class="title wow  animate__fadeIn">
            <!-- 设计人员执业资格/Qualification -->
            <img src="@/assets/images/img_qualification.png" alt="">
          </div>

          <div style="margin-top: 60px;">
            <Row :gutter="50">
              <Col :span="8">
              <div class="card">
                <div class="title2">技术顾问</div>
                <p>包装学院运输包装资深教授<br>
                  （博士生导师）</p>
              </div>
              </Col>
              <Col :span="8">
              <div class="card">
                <div class="title2">研发与设计</div>
                <p>国家物流师，包装工程师，助理包装工程师</p>
              </div>
              </Col>
              <Col :span="8">
              <div class="card">
                <div class="title2">包装工程力学</div>
                <p>相关专业博士</p>
              </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>

    <BackTop>
      <div class="top">
        <Icon type="md-arrow-up" />
      </div>
      返回顶部
    </BackTop>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  data () {
    return {
      activeIndex: 0
    }
  },
  computed: {
    ...mapGetters(['isLogin'])
  },
  mounted () {
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animate__animated',
      offset: 0,
      mobile: true
    })
    wow.init()
  }
}
</script>

<style lang="scss" scoped>
.first-screen {
    position: relative;
    height: 280px;
    background: linear-gradient(
        90.22deg,
        rgba(95, 181, 217, 0.85) 0.17%,
        rgba(103, 197, 236, 0.6885) 65.55%,
        rgba(94, 198, 181, 0.68) 99.8%
    );
    background-size: 100% 100%;
    color: #ffffff;
    z-index: 0;
    overflow: hidden;
    &::after {
        content: '';
        width: 100%;
        height: 141px;
        position: absolute;
        bottom: 0px;
        background: url(../../assets/images/bg_introduction_01.png) no-repeat center;
        background-position: center bottom;
        background-size: contain;
    }
    .inner {
        position: relative;
        width: 930px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }
    .text {
        margin: 0 auto;
        margin-top: 30px;
        text-align: center;
        h1 {
            margin: 0 80px;
            font-weight: 600;
            font-size: 36px !important;
            line-height: 70px;
        }
        .desc {
            font-size: 24px;
            line-height: 34px;
            color: #ffffff;
            border-left: 1px solid #ffffff;
            padding-left: 5px;
        }
        .text-btn {
            display: block;
            margin-top: 40px;
            width: 160px;
            height: 48px;
            line-height: 48px;
            background: #ffffff;
            border-radius: 10px;
            font-size: 14px;
            text-align: center;
            color: #5fb5d9;
        }
    }
}

.section {
    background: #ffffff;
    .title {
        margin: 0 80px;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        text-align: center;
        letter-spacing: 0.2em;
        color: #272727;
        border-bottom: 1.2px solid #67c5ec;
    }
    .desc {
        width: 782px;
        margin: 0 auto;
        letter-spacing: 0.05em;
        color: #8d8d8d;
        text-align: center;
    }
}

.intro {
  line-height: 24px;
  margin: 0 80px;
  margin-top: 40px;
}

.aboutus {
    margin-top: 60px;
    img {
        width: 100%;
        line-height: 1;
    }
    &-item {
        width: 330px;
        height: 100%;
        line-height: 1;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &-title {
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #000000;
        margin-bottom: 10px;
    }
    &-desc {
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #8d8d8d;
        font-size: 14px;
    }

    .btn-go {
        display: inline-block;
        width: 300px;
        height: 48px;
        line-height: 48px;
        background: #67c5ec;
        border-radius: 10px;
        text-align: center;
        color: #ffffff;
    }
    /deep/ .ivu-col {
        font-size: 0px;
    }
}

.applicable {
    margin-top: 40px;
    padding: 200px 0 200px;
    background: rgba(229, 229, 229, 0.6) url(../../assets/images/bg_functions.png) no-repeat left;
    background-size: contain;
    position: relative;
    z-index: 0;
    overflow: hidden;
    &::before {
        content: '';
        width: 100%;
        height: 141px;
        position: absolute;
        top: 0px;
        background: url(../../assets/images/bg_introduction_02.png) no-repeat center;
        background-size: contain;
        background-position: center top;
    }

    .applicable-item {
        position: relative;
        margin-bottom: 2px;
        width: 100%;
        height: 200px;
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
        overflow: hidden;
        img {
            display: block;
            width: 100%;
            transition: all 0.2s ease;
            opacity: 0.7;
        }
        &:hover {
            img {
                transform: scale(1.2);
                opacity: 1;
            }
        }
        .text {
            width: 120px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -60px;
            z-index: 999;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            letter-spacing: 0.2em;
            line-height: 20px;
            color: #ffffff;
        }
    }
}
.product-features {
    padding: 50px 0;
    background: url(../../assets/images/bg_aboutus_08.svg) no-repeat;
    background-position: 0px 150px;
    .features-wrap {
        padding: 0 80px;
        .features-item {
            position: relative;
            text-align: center;
            justify-content: center;
            cursor: pointer;
            margin-bottom: 20px;
            &-inner {
                display: flex;
                margin: 0 auto;
                width: 150px;
                height: 150px;
                align-items: center;
                justify-content: center;
                background: #67c5ec;
                border-radius: 100%;
                border: 20px solid #ffffff;
                color: #ffffff;
                margin-bottom: 20px;
                .iconfont {
                    font-size: 60px;
                }
            }
            &.is-active,
            &:hover {
                &::after {
                    display: block;
                }
                .features-item-inner {
                    background: #ffffff;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    color: #67c5ec;
                    .iconfont {
                        font-size: 82px;
                    }
                }
            }
        }
    }
    .features-intro {
        margin: 30px 10px;
        .top-border {
            height: 25px;
            background: #67c5ec;
        }
        .intro-inner {
            width: 100%;
            height: 185px;
            padding: 15px 40px;
            background: #ffffff;
            box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.25);
            .feat-name {
                border-left: 4px solid #67c5ec;
                padding-left: 10px;
                font-size: 18px;
                line-height: 25px;
                letter-spacing: 0.05em;
                color: #000000;
            }
            p {
                margin-top: 15px;
                padding-left: 14px;
            }
        }
    }
}

.product-function {
    margin-bottom: 80px;
    .function-item {
        position: relative;
        width: 100%;
        height: 445px;
        background: #67c5ec;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        &.bg1 {
            background: #67c5ec url(../../assets/images/bg_functioncard_01.svg) no-repeat top left;
        }
        &.bg2 {
            background: #67c5ec url(../../assets/images/bg_functioncard_03.svg) no-repeat center;
        }
        .pic {
            flex: 1;
            display: flex;
            align-items: center;
            text-align: center;
            .func-icon {
                margin: 0 auto;
                width: 154px;
                height: 154px;
                background: #99e1ff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                font-size: 100px;
            }
        }
        .function-meta {
            position: relative;
            width: 100%;
            bottom: 0;
            left: 0;
            height: 140px;
            background: #ffffff;
            border-radius: 0px 0px 5px 5px;
            padding: 20px;
            .meta-title {
                font-size: 18px;
                margin-bottom: 5px;
                text-align: center;
                color: #000000;
            }
            .meta-desc {
                color: #8d8d8d;
            }
            &::before {
                position: absolute;
                top: 0px;
                left: 50%;
                margin-left: -10px;
                content: ' ';
                width: 20px;
                height: 20px;
                border-width: 10px 10px 10px 10px;
                border-style: solid;
                border-color: #67c5ec transparent transparent transparent;
            }
        }
    }
}

.customers {
    margin-top: -200px;
    padding-top: 200px;
    padding-bottom: 100px;
    background: url(../../assets/images/bg_aboutus_09.svg) no-repeat;
    background-position: 100% 0;
    .card {
        margin: 10px;
        padding: 30px;
        height: 150px;
        background: #ffffff;
        border-radius: 5px;
        text-align: center;
        border: 1px solid #eee;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
        .title2 {
            font-weight: 600;
            font-size: 18px;
            padding-bottom: 10px;
            line-height: 25px;
            margin-bottom: 10px;
            border-bottom: 1.2px solid #67c5ec;
        }
    }
}

.top {
    width: 42px;
    height: 42px;
    margin: 0 auto;
    border-radius: 50%;
    background: #67c5ec;
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
}
/deep/ .ivu-back-top i {
    padding: 8px 0;
}
</style>

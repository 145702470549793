<template>
   <div class="manual-box-img" :style="manualBoxImgStyle">
    <!-- 图形1 无底半页箱 -->
    <!-- 高0 -->
    <div v-if="formValidate.boxTypeId !== '0200'" class="line half_Wide_T half_Wide_T1 vertical" :style="{'height': half_W_T  + 'px', 'left': sizeFactor45 + 'px', top: '-' + half_W_T + 'px'}"></div>
    <div v-if="formValidate.boxTypeId !== '0200'" class="line half_Wide_T half_Wide_T2 vertical" :style="{'height': half_W_T  + 'px', 'left': L_half_T +  sizeFactor45 + 'px', top: '-' + half_W_T + 'px'}"></div>
    <div v-if="formValidate.boxTypeId !== '0200' && formValidate.boxTypeId === '0204'" class="line half_Wide_T half_Wide_T3 vertical" :style="{'height': L_T  + 'px', 'left': L_half_T + T + sizeFactor45 + 'px', top: '-' + L_T + 'px'}"></div>
    <div v-if="formValidate.boxTypeId !== '0200' && formValidate.boxTypeId !== '0204'" class="line half_Wide_T half_Wide_T3 vertical" :style="{'height': half_W_T  + 'px', 'left': L_half_T + T + sizeFactor45 + 'px', top: '-' + half_W_T + 'px'}"></div>
    <div v-if="formValidate.boxTypeId !== '0200' && formValidate.boxTypeId === '0204'" class="line half_Wide_T half_Wide_T4 vertical" :style="{'height': L_T  + 'px', 'left': L_half_T + T + W + sizeFactor45 + 'px', top: '-' + L_T + 'px'}"></div>
    <div v-if="formValidate.boxTypeId !== '0200' && formValidate.boxTypeId !== '0204'" class="line half_Wide_T half_Wide_T4 vertical" :style="{'height': half_W_T  + 'px', 'left': L_half_T + T + W + sizeFactor45 + 'px', top: '-' + half_W_T + 'px'}"></div>
    <div v-if="formValidate.boxTypeId !== '0200'" class="line half_Wide_T half_Wide_T5 vertical" :style="{'height': half_W_T  + 'px', 'left': L_half_T + T + W + T + sizeFactor45 + 'px', top: '-' + half_W_T + 'px'}"></div>
    <div v-if="formValidate.boxTypeId !== '0200'" class="line half_Wide_T half_Wide_T6 vertical" :style="{'height': half_W_T  + 'px', 'left': L_half_T + T + W + T + L + sizeFactor45 + 'px', top: '-' + half_W_T + 'px'}"></div>
    <div v-if="formValidate.boxTypeId !== '0200' && formValidate.boxTypeId === '0204'" class="line half_Wide_T half_Wide_T7 vertical" :style="{'height': L_T  + 'px', 'left': L_half_T + T + W + T + L + T + sizeFactor45 + 'px', top: '-' + L_T + 'px'}"></div>
    <div v-if="formValidate.boxTypeId !== '0200' && formValidate.boxTypeId !== '0204'" class="line half_Wide_T half_Wide_T7 vertical" :style="{'height': half_W_T  + 'px', 'left': L_half_T + T + W + T + L + T + sizeFactor45 + 'px', top: '-' + half_W_T + 'px'}"></div>
    <div v-if="formValidate.boxTypeId !== '0200' && formValidate.boxTypeId === '0204'" class="line half_Wide_T half_Wide_T8 vertical" :style="{'height': L_T  + 'px', 'left': L_half_T + T + W + T + L + T + W_half_T + sizeFactor45 + 'px', top: '-' + L_T + 'px'}"></div>
    <div v-if="formValidate.boxTypeId !== '0200' && formValidate.boxTypeId !== '0204'" class="line half_Wide_T half_Wide_T8 vertical" :style="{'height': half_W_T  + 'px', 'left': L_half_T + T + W + T + L + T + W_half_T + sizeFactor45 + 'px', top: '-' + half_W_T + 'px'}"></div>
    <!-- L+T/2 -->
    <div v-if="formValidate.boxTypeId !== '0200'" class="line long_half_T horizontal" :style="{'width': L_half_T  + 'px', 'left': sizeFactor45 + 'px', top: '-' + half_W_T + 'px'}"></div>
    <!-- W -->
    <div v-if="formValidate.boxTypeId !== '0200' && formValidate.boxTypeId === '0204'" class="line Wide horizontal" :style="{'width': W +  'px', 'left': L_half_T + T + sizeFactor45 + 'px', top: '-' + L_T + 'px'}"></div>
    <div v-if="formValidate.boxTypeId !== '0200' && formValidate.boxTypeId !== '0204'" class="line Wide horizontal" :style="{'width': W +  'px', 'left': L_half_T + T + sizeFactor45 + 'px', top: '-' + half_W_T + 'px'}"></div>
    <!-- L -->
    <div v-if="formValidate.boxTypeId !== '0200'" class="line Long horizontal" :style="{'width': L +  'px', 'left': L_half_T + T + W + T + sizeFactor45 + 'px', top: '-' + half_W_T + 'px'}"></div>
    <!-- W+T/2 -->
    <div v-if="formValidate.boxTypeId !== '0200' && formValidate.boxTypeId === '0204'" class="line Long horizontal" :style="{'width': W_half_T +  'px', 'left': L_half_T + T + W + T + L +  T + sizeFactor45 + 'px', top: '-' + L_T + 'px'}"></div>
    <div v-if="formValidate.boxTypeId !== '0200' && formValidate.boxTypeId !== '0204'" class="line Long horizontal" :style="{'width': W_half_T +  'px', 'left': L_half_T + T + W + T + L +  T + sizeFactor45 + 'px', top: '-' + half_W_T + 'px'}"></div>
    <!-- 刻度尺-长 -->
    <div class="scale scale-long" v-if="selectEditVal === 1"  :style="{width: L_T  + 'px', height: 0, 'left': sizeFactor45  + 'px', 'top': H_15T / 4 + 'px'}">
      <!-- <Icon type="ios-arrow-back" class="arrow-back" size="12" /> -->
      <div class="arrow-right"></div>
      <!-- <div class="ruler-line"></div> -->
      <div class="arrow-left"></div>
      <!-- <Icon type="ios-arrow-forward" class="arrow-forward" size="12" /> -->
      <span>L = {{scaleLong}}</span>
    </div>
    <!-- 刻度尺-宽 -->
    <div class="scale scale-wide" v-if="selectEditVal === 1"  :style="{width: W_T + 'px', height: 0, 'left': L_T  + sizeFactor45 + 'px', 'top': H_15T / 2 + 'px'}">
      <!-- <Icon type="ios-arrow-back" class="arrow-back" size="12" /> -->
      <div class="arrow-right"></div>
      <!-- <div class="ruler-line"></div> -->
      <div class="arrow-left"></div>
      <!-- <Icon type="ios-arrow-forward" class="arrow-forward" size="12" /> -->
      <span>W = {{scaleWide}}</span>
    </div>
    <!-- 刻度尺-高 -->
    <div class="scale scale-high" v-if="selectEditVal === 1"  :style="{height: H_15T  + 'px', 'line-height': H_15T   + 'px', width: 0, 'left': L_T + W_T + L_T / 2 + sizeFactor45 + 'px', 'top': 0}">
      <!-- <Icon type="ios-arrow-up" class="arrow-up" size="12" /> -->
      <div class="arrow-up"></div>
      <!-- <div class="ruler-line"></div> -->
      <div class="arrow-down"></div>
      <!-- <Icon type="ios-arrow-down" class="arrow-down" size="12" /> -->
      <span><span class="scale-high-val">H = {{scaleHigh}}</span></span>
    </div>
    <!-- 长L+T -->
    <div class="line Long_T Long_T_1 horizontal" :class="{'dotted_line': formValidate.boxTypeId !== '0200'}" :style="{'width': L_T  + 'px', 'left': sizeFactor45 + 'px'}"></div>
    <div class="line Long_T Long_T_2 horizontal dotted_line" :style="{'width': L_T  + 'px', 'left': sizeFactor45 + 'px', top: H_15T + 'px'}"></div>
    <div class="line Long_T Long_T_3 horizontal" :class="{'dotted_line': formValidate.boxTypeId !== '0200'}" :style="{'width': L_T  + 'px', 'left': L_T + W_T + sizeFactor45 + 'px',}"></div>
    <div class="line Long_T Long_T_3 horizontal dotted_line" :style="{'width': L_T  + 'px', 'left': L_T + W_T + sizeFactor45 + 'px', top: H_15T + 'px'}"></div>
    <!-- 宽W+T -->
    <div class="line Wide_T Wide_T1 horizontal" :class="{'dotted_line': formValidate.boxTypeId !== '0200'}" :style="{'width': W_T + 'px', 'left': L_T + sizeFactor45  + 'px'}"></div>
    <div class="line Wide_T Wide_T2 horizontal dotted_line" :style="{'width': W_T + 'px', 'left': L_T + sizeFactor45  + 'px',top: H_15T + 'px'}"></div>
    <div class="line Wide_T Wide_T3 horizontal" :class="{'dotted_line': formValidate.boxTypeId !== '0200'}" :style="{'width': W_T + 'px', 'left': 2 * L_T + W_T + sizeFactor45  + 'px'}"></div>
    <div class="line Wide_T Wide_T3 horizontal dotted_line" :style="{'width': W_T + 'px', 'left': 2 * L_T + W_T + sizeFactor45  + 'px',top: H_15T + 'px'}"></div>
    <!-- 高 -->
    <div class="line High_T High_T1 vertical" :style="{'height': H_15T  + 'px', 'left': 0}"></div>
    <div class="line High_T High_T2 vertical dotted_line" :style="{'height': H_15T  + 'px', 'left': sizeFactor45  + 'px'}"></div>
    <div class="line High_T High_T3 vertical dotted_line" :style="{'height': H_15T  + 'px', 'left': L_T + sizeFactor45  + 'px'}"></div>
    <div class="line High_T High_T4 vertical dotted_line" :style="{'height': H_15T  + 'px', 'left': L_T + W_T + sizeFactor45  + 'px'}"></div>
    <div class="line High_T High_T5 vertical dotted_line" :style="{'height': H_15T  + 'px', 'left': 2 * L_T + W_T + sizeFactor45  + 'px'}"></div>
    <div class="line High_T High_T6 vertical" :style="{'height': H_15T  + 'px', 'left': 2 * L_T + 2 * W_T + sizeFactor45  + 'px'}"></div>
    <!-- 高2 -->
    <div class="line half_Wide_T half_Wide_T1 vertical" :style="{'height': half_W_T  + 'px', 'left': sizeFactor45 + 'px', top: H_15T + 'px'}"></div>
    <div class="line half_Wide_T half_Wide_T2 vertical" :style="{'height': half_W_T  + 'px', 'left': L_half_T +  sizeFactor45 + 'px', top: H_15T + 'px'}"></div>
    <div class="line half_Wide_T half_Wide_T3 vertical" v-if="formValidate.boxTypeId === '0204'" :style="{'height': L_T  + 'px', 'left': L_half_T + T + sizeFactor45 + 'px', top: H_15T + 'px'}"></div>
    <div class="line half_Wide_T half_Wide_T3 vertical" v-else :style="{'height': half_W_T  + 'px', 'left': L_half_T + T + sizeFactor45 + 'px', top: H_15T + 'px'}"></div>
    <div class="line half_Wide_T half_Wide_T4 vertical" v-if="formValidate.boxTypeId === '0204'" :style="{'height': L_T  + 'px', 'left': L_half_T + T + W + sizeFactor45 + 'px', top: H_15T + 'px'}"></div>
    <div class="line half_Wide_T half_Wide_T4 vertical" v-else :style="{'height': half_W_T  + 'px', 'left': L_half_T + T + W + sizeFactor45 + 'px', top: H_15T + 'px'}"></div>
    <div class="line half_Wide_T half_Wide_T5 vertical" :style="{'height': half_W_T  + 'px', 'left': L_half_T + T + W + T + sizeFactor45 + 'px', top: H_15T + 'px'}"></div>
    <div class="line half_Wide_T half_Wide_T6 vertical" :style="{'height': half_W_T  + 'px', 'left': L_half_T + T + W + T + L + sizeFactor45 + 'px', top: H_15T + 'px'}"></div>
    <div class="line half_Wide_T half_Wide_T7 vertical" v-if="formValidate.boxTypeId === '0204'"  :style="{'height': L_T  + 'px', 'left': L_half_T + T + W + T + L + T + sizeFactor45 + 'px', top: H_15T + 'px'}"></div>
    <div class="line half_Wide_T half_Wide_T7 vertical" v-else :style="{'height': half_W_T  + 'px', 'left': L_half_T + T + W + T + L + T + sizeFactor45 + 'px', top: H_15T + 'px'}"></div>
    <div class="line half_Wide_T half_Wide_T8 vertical" v-if="formValidate.boxTypeId === '0204'" :style="{'height': L_T  + 'px', 'left': L_half_T + T + W + T + L + T + W_half_T + sizeFactor45 + 'px', top: H_15T + 'px'}"></div>
    <div class="line half_Wide_T half_Wide_T8 vertical" v-else :style="{'height': half_W_T  + 'px', 'left': L_half_T + T + W + T + L + T + W_half_T + sizeFactor45 + 'px', top: H_15T + 'px'}"></div>
    <!-- L+T/2 -->
    <div class="line long_half_T horizontal" :style="{'width': L_half_T  + 'px', 'left': sizeFactor45 + 'px', top: H_15T + half_W_T + 'px'}"></div>
    <!-- W -->
    <div class="line Wide horizontal" v-if="formValidate.boxTypeId === '0204'" :style="{'width': W +  'px', 'left': L_half_T + T + sizeFactor45 + 'px', top: H_15T + L_T + 'px'}"></div>
    <div class="line Wide horizontal" v-else :style="{'width': W +  'px', 'left': L_half_T + T + sizeFactor45 + 'px', top: H_15T + half_W_T + 'px'}"></div>
    <!-- L -->
    <div class="line Long horizontal" :style="{'width': L +  'px', 'left': L_half_T + T + W + T + sizeFactor45 + 'px', top: H_15T + half_W_T + 'px'}"></div>
    <!-- W+T/2 -->
    <div class="line Long horizontal" v-if="formValidate.boxTypeId === '0204'" :style="{'width': W_half_T +  'px', 'left': L_half_T + T + W + T + L +  T + sizeFactor45 + 'px', top: H_15T + L_T + 'px'}"></div>
    <div class="line Long horizontal" v-else :style="{'width': W_half_T +  'px', 'left': L_half_T + T + W + T + L +  T + sizeFactor45 + 'px', top: H_15T + half_W_T + 'px'}"></div>
    <!-- 其他 -->
    <div class="line other horizontal" :style="{'width': sizeFactor45 + 'px', 'left': 0, top: 0}"></div>
    <div class="line other horizontal" :style="{'width': sizeFactor45 + 'px', 'left': 0, top: H_15T + 'px'}"></div>

    <!--  -->
    <div class="slotting-box slotting_1" :style="{'width': T + 'px', 'height': T + 'px','left': L_half_T + sizeFactor45 + 'px', top: H_15T + 'px'}"></div>
    <div class="slotting-box slotting_2" :style="{'width': T + 'px', 'height': T + 'px','left': W + L_half_T + T + sizeFactor45 +  'px', top: H_15T + 'px'}"></div>
    <div class="slotting-box slotting_3" :style="{'width': T + 'px', 'height': T + 'px','left': L + L_half_T + T + W + T + sizeFactor45 +  'px', top: H_15T + 'px'}"></div>
    <div class="slotting-box slotting_4" v-if="formValidate.boxTypeId !== '0200'" :style="{'width': T + 'px', 'height': T + 'px','left': L_half_T + sizeFactor45 + 'px', top: 0}"></div>
    <div class="slotting-box slotting_5" v-if="formValidate.boxTypeId !== '0200'" :style="{'width': T + 'px', 'height': T + 'px','left': W + L_half_T + T + sizeFactor45 +  'px', top: 0}"></div>
    <div class="slotting-box slotting_6" v-if="formValidate.boxTypeId !== '0200'" :style="{'width': T + 'px', 'height': T + 'px','left': L + L_half_T + T + W + T + sizeFactor45 +  'px', top: 0}"></div>
  </div>
</template>
<script>
export default {
  name: 'ManualBoxImg',
  props: {
    formValidate: Object
  },
  data () {
    return {}
  },
  computed: {
    selectEditVal () {
      return this.$parent.selectEditVal
    },
    sizeFactor () {
      var long = this.formValidate.long
      var wide = this.formValidate.wide
      var high = this.formValidate.high
      // var maxVal = long > wide ? (long > high ? long : high) : (wide > high ? wide : high)
      // var minVal = long < wide ? (long < high ? long : high) : (wide < high ? wide : high)
      // var ratio = parseInt(maxVal / minVal)
      var ratio1 = 0.5
      var ratio2 = 0.5
      var thickness = this.formValidate.thickness
      switch (this.formValidate.boxTypeId) {
        case '0200':
          ratio1 = 750 / (2 * long + 2 * wide + 4 * thickness)
          ratio2 = 400 / (wide / 2 + high + 2.5 * thickness)
          break
        case '0201':
          ratio1 = 750 / (2 * long + 2 * wide + 4 * thickness)
          ratio2 = 400 / (wide + high + 4.5 * thickness)
          break
        case '0203':
          ratio1 = 750 / (2 * long + 2 * wide + 4 * thickness)
          ratio2 = 400 / (2 * long + high + 4.5 * thickness)
          break
        case '0204':
          ratio1 = 750 / (2 * long + 2 * wide + 4 * thickness)
          // 需要取高的最大值
          var high1 = 2 * wide + high + 4.5 * thickness
          var high2 = 2 * long + high + 4.5 * thickness
          var maxHigh = high1 > high2 ? high1 : high2
          ratio2 = 400 / maxHigh
          break
        default:
          break
      }
      var ratio = ratio1 > ratio2 ? ratio2 : ratio1
      return ratio
    },
    ratioLong () {
      var ratio = 0.5
      var long = this.formValidate.long
      var wide = this.formValidate.wide
      switch (this.formValidate.boxTypeId) {
        case '0200':
          ratio = 700 / (2 * long + 2 * wide + 4 * this.T)
          break
        case '0201':
          ratio = 700 / (2 * long + 2 * wide + 4 * this.T)
          break
        case '0203':
          ratio = 700 / (2 * long + 2 * wide + 4 * this.T)
          break
        case '0204':
          ratio = 700 / (2 * long + 2 * wide + 4 * this.T)
          break
        default:
          break
      }
      return ratio
    },
    ratioHigh () {
      var ratio = 0.5
      var long = this.formValidate.long
      var wide = this.formValidate.wide
      var high = this.formValidate.high
      switch (this.formValidate.boxTypeId) {
        case '0200':
          ratio = 350 / (wide / 2 + high + 2.5 * this.T)
          break
        case '0201':
          ratio = 350 / (wide + high + 4.5 * this.T)
          break
        case '0203':
          ratio = 350 / (long + high + 4.5 * this.T)
          break
        case '0204':
          ratio = 350 / (long + high + 4.5 * this.T)
          break
        default:
          break
      }
      return ratio
    },
    L () {
      return this.formValidate.long * this.sizeFactor
    },
    W () {
      return this.formValidate.wide * this.sizeFactor
    },
    T () {
      return this.formValidate.thickness * this.sizeFactor
    },
    L_T () {
      return (
        this.formValidate.long * this.sizeFactor + this.T
      )
    },
    W_T () {
      return (
        this.formValidate.wide * this.sizeFactor + this.T
      )
    },
    H_15T () {
      var boxHigh = this.formValidate.high * this.sizeFactor + 1.5 * this.T
      if (this.formValidate.boxTypeId !== '0200') {
        boxHigh = this.formValidate.high * this.sizeFactor + 2.5 * this.T
      }
      return boxHigh
    },
    half_W_T () {
      var halfWide = (this.formValidate.wide * this.sizeFactor) / 2 + this.T
      if (this.formValidate.boxTypeId !== '0200' && this.formValidate.boxTypeId !== '0201') {
        halfWide = this.formValidate.wide * this.sizeFactor + this.T
      }
      return halfWide
    },
    L_half_T () {
      return (
        this.formValidate.long * this.sizeFactor +
        this.T / 2
      )
    },
    W_half_T () {
      return (
        this.formValidate.wide * this.sizeFactor +
        this.T / 2
      )
    },
    sizeFactor45 () {
      if (45 * this.sizeFactor > 45) {
        return 45 * this.sizeFactor > 30 ? 30 : 45 * this.sizeFactor
      } else {
        return 45 * this.sizeFactor < 10 ? 10 : 45 * this.sizeFactor
      }
    },
    manualBoxImgStyle () {
      var boxStyle = {}

      var long = this.formValidate.long
      var wide = this.formValidate.wide
      var high = this.formValidate.high

      switch (this.formValidate.boxTypeId) {
        case '0200':
          boxStyle = {
            width: (2 * long + 2 * wide) * this.sizeFactor + 'px',
            height: (wide / 2 + high) * this.sizeFactor + 2.5 * this.T + 'px'
          }
          break
        case '0201':
          boxStyle = {
            width: (2 * long + 2 * wide) * this.sizeFactor + 'px',
            height: (wide + high) * this.sizeFactor + 4.5 * this.T + 'px',
            'margin-top': (wide / 2 + high / 2 + this.T) * this.sizeFactor + 'px'
          }
          break
        case '0203':
          boxStyle = {
            width: (2 * long + 2 * wide) * this.sizeFactor + 'px',
            height: (wide * 2 + high) * this.sizeFactor + 4.5 * this.T + 'px',
            'margin-top': (wide + high / 2 + this.T) * this.sizeFactor + 'px'
          }
          break
        case '0204':
          boxStyle = {
            width: (2 * long + 2 * wide) * this.sizeFactor + 'px',
            height: (long * 2 + high) * this.sizeFactor + 4.5 * this.T + 'px',
            'margin-top': (long + high / 2 + this.T) * this.sizeFactor + 'px'
          }
          break
        default:
          boxStyle = {
            width: (2 * long + 2 * wide) * this.sizeFactor,
            height: (wide / 2 + high) * this.sizeFactor + 2.5 * this.T
          }
          break
      }
      return boxStyle
    },
    scaleLong () {
      return this.formValidate.long + this.formValidate.thickness
    },
    scaleWide () {
      return this.formValidate.wide + this.formValidate.thickness
    },
    scaleHigh () {
      var scaleVal = this.formValidate.high + 1.5 * this.formValidate.thickness
      if (this.formValidate.boxTypeId !== '0200') {
        scaleVal = this.formValidate.high + 2.5 * this.formValidate.thickness
      }
      return scaleVal
    }
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.manual-box-img {
  position: relative;
  .line {
    position: absolute;
  }

  .line.horizontal {
    height: 0;
    border-top: 1px solid #000000;
    &.dotted_line {
      border-top: 1px dashed #000000;
    }
  }
  .line.vertical {
    width: 0;
    border-left: 1px solid #000000;
    &.dotted_line {
      border-left: 1px dashed #000000;
    }
  }
  .scale{
    position: absolute;
    color: #ee7e36;
    text-align: center;
    .ruler-line{
      width: 100%;
      border: 0.5px  solid rgb(238, 126, 54);
      position: absolute;
      bottom: 0;
      background: #ee7e36;
    }
    i.arrow-back{
      position: absolute;
      top: -6.5px;
      left: -4px;
    }
    i.arrow-forward{
      position: absolute;
      right: -4px;
      top: -6.5px;
    }
  }
  .scale.scale-high{
    .ruler-line{
      height: 100%;
      width: 0;
      border: 0.5px solid rgb(238, 126, 54);
      position: absolute;
      bottom: 0;
      background: #ee7e36;
    }
    i.arrow-up{
      position: absolute;
      top: -3px;
      left: -5.5px;
    }
    i.arrow-down{
      position: absolute;
      left: -5.5px;
      bottom: -4px;
    }
    span{
      margin-left: 5px;
    }
    span.scale-high-val{
      line-height: 20px;
      display: inline-block;
      width: 100px;
      text-align: left;
    }
  }
  .scale-long,
  .scale-wide{
    border-top: 1.5px solid #ee7e36;
  }
  .scale-high{
    border-left: 1.5px solid #ee7e36;
  }
  .arrow-right {
    width: 6px;
    height: 6px;
    position: absolute;
    top: -4px;
    right: 0.5px;
    background-color: transparent; /* 模块背景为透明 */
    border-color: #ee7e36;
    border-style: solid;
    border-width: 1px 1px 0 0;
    transform: rotate(45deg); /*箭头方向可以自由切换角度*/
  }
  .arrow-left {
    width: 6px;
    height: 6px;
    position: absolute;
    top: -4px;
    left: 0.5px;
    background-color: transparent; /* 模块背景为透明 */
    border-color: #ee7e36;
    border-style: solid;
    border-width: 1px 1px 0 0;
    transform: rotate(-135deg); /*箭头方向可以自由切换角度*/
  }
  .arrow-up {
    width: 6px;
    height: 6px;
    position: absolute;
    top: 1.5px;
    left: -3.5px;
    background-color: transparent; /* 模块背景为透明 */
    border-color: #ee7e36;
    border-style: solid;
    border-width: 1px 1px 0 0;
    transform: rotate(-45deg); /*箭头方向可以自由切换角度*/
  }
  .arrow-down {
    width: 6px;
    height: 6px;
    position: absolute;
    left: -3.5px;
    bottom: 1px;
    background-color: transparent; /* 模块背景为透明 */
    border-color: #ee7e36;
    border-style: solid;
    border-width: 1px 1px 0 0;
    transform: rotate(135deg); /*箭头方向可以自由切换角度*/
  }
  .slotting-box{
    position: absolute;
    border-top: 1px solid #000000;
  }
}
</style>

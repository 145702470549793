/*
 * @Author: jiaxing.bu jiaxing.bu@gientech.com
 * @Date: 2022-08-30 11:51:59
 * @LastEditors: jiaxing.bu jiaxing.bu@gientech.com
 * @LastEditTime: 2022-08-30 14:03:38
 * @FilePath: \vite-vue3-elementPlus\src\utils\htmlToImg.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import html2canvas from 'html2canvas'

function createImg (ele) {
  const content = ele
  const scrollHeight = content.scrollHeight
  const scrollWidth = content.scrollWidth
  html2canvas(content, {
    scale: window.devicePixelRatio * 2,
    useCORS: true, // 开启跨域配置，但和allowTaint不能共存
    width: scrollWidth,
    height: scrollHeight,
    windowWidth: scrollWidth,
    windowHeight: scrollHeight,
    x: 0,
    y: window.pageYOffset
  }).then(canvas => {
    this.operType = 'edit'
    const dataURL = canvas.toDataURL('image/jpg')
    const link = document.createElement('a')
    link.href = dataURL
    const filename = `${new Date().getTime()}.jpg` // 文件名称
    link.setAttribute('download', filename)
    link.style.display = 'none' // a标签隐藏
    document.body.appendChild(link)
    link.click()
  })
}

export default {
  createImg
}
